@import "./base.scss";

/* BASE */
body {
  color: $txt-color;
  font-size: $font-size;
  font-family: $font-family-regular;
  @include scrollbarMain;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
section {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-header;
}
h1 {
  padding-bottom: 20px;
  font-size: $font-size-xl;
  font-weight: normal;
}
h2 {
  padding-bottom: 20px;
  font-size: $font-size-xl;
  font-weight: normal;
}
a {
  color: $txt-color;
  @include transition($animation);
}
a {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}

/*special hover*/
a.hover {
  position: relative;
  display: inline-block;
  text-decoration: none;
  @include transition($animation);
  &:before {
    content: "";
    position: absolute;
    overflow: hidden;
    margin: auto;
    height: 1px;
    bottom: -3px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ffffff;
    width: 0px;
    opacity: 0;
    @include transition($animation);
  }
  &:hover {
    text-decoration: none;
    @include transition($animation);
    &:before {
      width: 100%;
      opacity: 1;
    }
  }
}
/*end special hover*/
p {
  padding-bottom: 24px;
  line-height: 1.7em;
}
p:last-child {
  padding-bottom: 0;
}

ul li {
  list-style: none;
}
ol {
  list-style-position: inside;
}

b,
.bold,
.font-bold {
  font-family: $font-family-bold;
}
.font-medium {
  font-family: $font-family-medium;
}
.font-regular {
  font-family: $font-family-regular;
}
.font-black {
  font-family: $font-family-black;
}
.font-light {
  font-family: $font-family-light;
}
.font-thin {
  font-family: $font-family-thin;
}

.animation {
  @include transition($animation);
}
.txt-shadow {
  @include text-shadow($txt-shadow);
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: inline-block;
}

.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-justify {
  text-align: justify;
}

.align-left {
  display: flex;
  justify-content: flex-start;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.align-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.align-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.align-space-around {
  display: flex;
  justify-content: space-around;
}
.align-space-between {
  display: flex;
  justify-content: space-between;
}
.uppercase {
  text-transform: uppercase;
}
/*end BASE*/

/*LAYOUT*/

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "bold";
  src: local("CorsaBold"),
    url("./fonts/CorsaGrotesk-Bold.otf") format("truetype");
}
@font-face {
  font-family: "medium";
  src: local("CorsaMedium"),
    url("./fonts/CorsaGrotesk-Medium.otf") format("truetype");
}
@font-face {
  font-family: "regular";
  src: local("CorsaRegular"),
    url("./fonts/CorsaGrotesk-Regular.otf") format("truetype");
}
@font-face {
  font-family: "light";
  src: local("CorsaLight"),
    url("./fonts/CorsaGrotesk-Light.otf") format("truetype");
}
@font-face {
  font-family: "medium-italic";
  src: local("CorsaMediumItalic"),
    url("./fonts/CorsaGrotesk-MediumItalic.otf") format("truetype");
}
@font-face {
  font-family: "thin";
  src: local("CorsaThin"),
    url("./fonts/CorsaGrotesk-Thin.otf") format("truetype");
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  // speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import '../../base.scss';

.functionalities-section-screen.functionalities-chat-container {
    .tabs-container {
        width: 70%;
        max-width: 70%;
        // height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        @include hiddenScrollbar;
      }

    transition: background-image 0.5s ease-in-out;
    .left-container {
        transition: background-image 0.5s ease-in-out;
    }
}